import React, {
  ReactElement,
  useState,
  SyntheticEvent,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { Box, Text } from 'rebass/styled-components';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import { confirmAlert } from 'utils/confirm-alert';
import {
  useDocumentTitle,
  useBrokenLinksForProcedures,
  useGetExternalLinksForAccount,
} from 'hooks';
import useOnClickOutside from 'use-onclickoutside';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Dropdown,
  DropdownButton,
  DropdownMenu,
  StyledDropdown,
} from 'ui/patterns';
import * as Icon from 'assets/icons';
import { Button, Checkbox, Stack } from 'ui/components';
import {
  FileBrowserBtn,
  BrokenLinkCount,
  ProcedureSearch,
  ProcedureList,
  ProcedureListItem,
  ProcedureBrokenLinks,
  FilterMenu,
  FilterMenuItem,
  FilterMenuItemLabel,
  FilterMenuActions,
} from '../styles';
import NoDataImage from 'assets/images/NoDataImage';
import { ProcedureListType, ProcedureInfoType } from 'types/procedureBuilder';
import LinkedGlobalAccountsList from '../LinkedGlobalAccountsList';
import LinkedAccountsList from '../LinkedAccountsList';
import { showSuccessToast, showErrorToast } from 'utils/showToast';
import { ProceduresListPropType } from '../procedureTypes';

export default function ProceduresLists({
  accountId,
  totalBrokenLinksCount,
  isProcedureLoading,
  procedures,
  selectedProcedures,
  setSelectedProcedures,
  updateProcedureVisibility,
  renameProc,
  setRenameProc,
  handleRenameProcedures,
  searchValue,
  setSearchValue,
  selectProcedure,
  appliedFilterOption,
  setAppliedFilterOption,
  addProcedure,
  importProcedure,
  linkProcedure,
  toggleFileBrowser,
  setRenameFailed,
  renameFailed,
  brokenLinks,
  autoSaveContent,
  procedureDetails,
  setUserTyped,
  setEditorChanges,
  saveContent,
  userTyped,
}: ProceduresListPropType): ReactElement {
  useDocumentTitle('Procedure Builder', true);

  const { refetch: refetchBrokenLinks, isFetching: isBrokenLinksLoading } =
    useBrokenLinksForProcedures(accountId);

  const {
    data: externalLinks,
    refetch: refetchExternalLinks,
    isFetching: isExternalLinksLoading,
  } = useGetExternalLinksForAccount(accountId);

  //Handling global and linked account modals in the procedure
  const [globalProcedure, setGlobalProcedure] = useState<ProcedureListType>();
  const [linkedProcedure, setLinkedProcedure] = useState<ProcedureListType>();

  const toggleLinkedGlobalAccounts = (procedureItem?: ProcedureListType) => {
    setGlobalProcedure(procedureItem);
  };
  const toggleLinkedAccounts = (procedureItem?: ProcedureListType) => {
    setLinkedProcedure(procedureItem);
  };

  const handleFileBrowser = () => {
    toggleFileBrowser();
  };
  const [filterOption, setFilterOption] = useState<string>('');

  useEffect(() => {
    setFilterOption(appliedFilterOption);
  }, [appliedFilterOption]);

  const linkProcedureHandler = (accountId: number) => {
    linkProcedure();
  };

  const procedureAddOptions = [
    {
      value: 'Add Procedure',
      icon: <Icon.DocumentAdd title="" ariaHidden="true" />,
      groupEnd: true,
      action: () => {
        addProcedure();
      },
    },
    {
      value: 'Import Procedure',
      icon: <Icon.Import title="" ariaHidden="true" />,
      groupEnd: true,
      action: () => {
        // history.push('/home/procedure-builder/import-procedure', {
        //   ID: accountId,
        // });
        // handleNotImplemented();
        importProcedure();
      },
    },
    {
      value: 'Link Procedure',
      icon: <Icon.DocumentLink title="" ariaHidden="true" />,
      action: () => {
        linkProcedureHandler(accountId);
      },
    },
  ];
  const procedureAddDropdown = () => (
    <Button size="sm" variant="subtle">
      <Icon.DotsVertical title="Options" />
    </Button>
  );

  const procedureFilterOptions = [
    {
      id: 1,
      value: 'Procedures with broken links',
      icon: <Icon.Link />,
    },
    {
      id: 2,
      value: 'Un-published procedures',
      icon: <Icon.ExternalLink />,
    },
    {
      id: 3,
      value: 'Linked procedures',
      icon: <Icon.DocumentLink />,
    },
    {
      id: 4,
      value: 'Global procedures',
      icon: <Icon.Globe />,
    },
    {
      id: 5,
      value: 'Visible procedures',
      icon: <Icon.Eye />,
    },
    {
      id: 6,
      value: 'Invisible procedures',
      icon: <Icon.EyeOff />,
    },
  ];

  const isProcedureChecked = (id: number) => {
    const item =
      selectedProcedures && selectedProcedures.find(item => item === id);
    if (item) {
      return true;
    } else return false;
  };

  const checkIsPublished = (procedureItem: ProcedureListType): boolean => {
    if (
      (!procedureItem.IsLinked || procedureItem.IsParentLinked) &&
      !procedureItem.Procedure?.IsGlobal
    ) {
      if (procedureItem.Procedure.Versions.length === 0) {
        return true;
      } else {
        const seconds =
          (new Date(procedureItem.Procedure.LastUpdatedOn).getTime() -
            new Date(
              procedureItem.Procedure.Versions[
                procedureItem.Procedure.Versions.length - 1
              ].CreatedOn,
            ).getTime()) /
          1000;
        if (Math.floor(seconds) > 0) return true;
        else return false;
      }
    } else {
      if (procedureItem.IsPublished) {
        if (
          (!procedureItem.IsLinked || procedureItem.IsParentLinked) &&
          !procedureItem.Procedure?.IsGlobal
        ) {
          return true;
        }
        return false;
      }
      return false;
    }
  };

  const isCheckedAllProcedures = () => {
    if (
      selectedProcedures?.length ===
      (procedures && procedures.length > 0 && procedures.length)
    )
      return true;
    else return false;
  };

  const selectAllProcedureAction = () => {
    setRenameProc(undefined);
    let allprocedures: Array<number> = [];
    const isSelect = isCheckedAllProcedures();
    if (!isSelect) {
      allprocedures =
        procedures && procedures.length > 0
          ? procedures.map(item => {
              return item.ProcedureId;
            })
          : [];
    } else {
      allprocedures = [];
    }
    if (allprocedures) setSelectedProcedures([...allprocedures]);
  };
  const handleSelectAllProcedures = () => {
    let newContent = '';
    if ((window as any)?.tinymce?.activeEditor?.initialized === true) {
      newContent = `<!DOCTYPE html><html><head><title></title><base href='/api/procedures/V1/accountfolders/${
        procedureDetails?.AccountProcedure?.AccountId
      }/Policies/'></head><body>${(
        window as any
      )?.tinymce?.activeEditor?.getContent()}</body></html>`;
    }
    if (userTyped === true && procedureDetails?.Content !== newContent) {
      confirmAlert({
        message: 'Unsaved changes are present do you want to save the changes?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              saveContent(() => {
                setUserTyped(false);
                setEditorChanges(false);
                procedures.forEach(item => {
                  if (
                    item.ProcedureId ===
                    procedureDetails?.AccountProcedure?.Procedure?.Id
                  ) {
                    if (
                      (!item.IsLinked || item.IsParentLinked) &&
                      !item.Procedure?.IsGlobal
                    ) {
                      item.IsPublished = true;
                    }
                  }
                  return item;
                });
              });
              selectAllProcedureAction();
            },
            className: 'pr-btn-primary',
          },
          {
            label: 'No',
            onClick: () => {
              //
            },
          },
        ],
      });
    } else {
      selectAllProcedureAction();
    }
  };

  /*********SINGLE PROCEDURE VISIBILITY TOGGLE***************************/
  //Do not show again checkbox//
  const handleSingleToggleModalVisiblity = (e: any) => {
    if (e.target.checked === true) {
      localStorage.setItem(
        'singleVisibilityModalConfirmation',
        JSON.stringify(true),
      );
    } else {
      localStorage.removeItem('singleVisibilityModalConfirmation');
    }
  };
  //Function to handle apis
  const changeProcedureVisibility = (procItem: ProcedureListType) => {
    updateProcedureVisibility(
      {
        ...procItem,
        IsVisible: !procItem.IsVisible,
      },
      {
        onSuccess: () => {
          showSuccessToast({
            message: 'Your changes were saved successfully.',
          });
          setSearchValue('');
        },
        onError: (error: { message: string }) => {
          showErrorToast({
            message: error.message,
          });
        },
      },
    );
  };

  const confirmVisibilityToggle = (
    procItem: ProcedureListType,
    cb: (isSuccess: boolean) => void,
  ) => {
    setTimeout(() => {
      if (procItem.IsLinked || procItem?.Procedure?.IsGlobal) {
        confirmAlert({
          message:
            'You are about to change the visibility of a global and/or linked procedure. This change would affect all the other accounts to which it has been added. Do you want to proceed?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                changeProcedureVisibility(procItem);
              },
              className: 'pr-btn-primary',
            },
            {
              label: 'No',
              onClick: () => {
                cb(false);
                //
              },
            },
          ],
          onClickOutside: () => cb(false),
          onKeypressEscape: () => cb(false),
        });
      } else {
        changeProcedureVisibility(procItem);
      }
    }, 10);
  };
  function hideDisplayProcedure(
    procItem: ProcedureListType,
    cb: (isSuccess: boolean) => void,
  ) {
    const tempValue = localStorage.getItem('singleVisibilityModalConfirmation');
    const showConfirmation = tempValue === null ? false : JSON.parse(tempValue);
    const isFirstTimeProcedure =
      !procItem?.Procedure?.Versions.length && !procItem.IsVisible;
    if (!showConfirmation) {
      confirmAlert({
        dontShowAgain: isFirstTimeProcedure ? false : true,
        handleModalVisibility: handleSingleToggleModalVisiblity,
        message: isFirstTimeProcedure
          ? 'One or more of the selected procedures have never been published and are not yet available to users. In order to add them to the Policies Menu, all such procedures will also be published. Proceed ? '
          : `The procedure will be ${
              procItem.IsVisible === true
                ? 'removed from the'
                : 'displayed in the '
            }  Policies Menu,  ${
              procItem.IsVisible === true
                ? 'but procedures will still be able to link to it.'
                : ''
            }`,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {
              confirmVisibilityToggle(procItem, cb);
            },
            className: 'pr-btn-primary',
          },
          {
            label: 'Cancel',
            onClick: () => {
              cb(false);
              //
            },
          },
        ],
        onClickOutside: () => cb(false),
        onKeypressEscape: () => cb(false),
      });
    } else {
      confirmVisibilityToggle(procItem, cb);
    }
  }
  /*********SINGLE PROCEDURE VISIBILITY TOGGLE**************************/

  const handleBrokenLinkChecker = () => {
    refetchExternalLinks();
    refetchBrokenLinks();
  };

  const [open, setOpen] = useState(false);
  const handleOnClickOutside = () => {
    setOpen(false);
  };
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(wrapperRef, handleOnClickOutside);
  const handleToggle = () => {
    setOpen(true);
  };

  return (
    <>
      <Card width="345px" flexDirection="column" style={{ display: 'flex' }}>
        <CardHeader
          py={1}
          px={3}
          bg="var(--color-neutral-contrast-01)"
          alignItems="center"
          style={{ display: 'flex' }}
        >
          <Text as="h4" fontWeight="medium" lineHeight={1} color="text.body">
            Procedures
          </Text>
          <Tooltip
            animation="fade"
            title="Broken links in this account"
            position="top"
            arrow={true}
          >
            <BrokenLinkCount
              fetching={isBrokenLinksLoading || isExternalLinksLoading}
            >
              <Icon.Link title="Broken links " />
              <Text>{totalBrokenLinksCount}</Text>
              <button onClick={handleBrokenLinkChecker}>
                <Icon.Refresh />
              </button>
            </BrokenLinkCount>
          </Tooltip>
          <Box ml="auto" mr="-4px">
            <Dropdown
              trigger={procedureAddDropdown()}
              menuList={procedureAddOptions}
              position="left"
              className="dd-listitem-options"
            />
          </Box>
        </CardHeader>
        <ProcedureSearch>
          <Checkbox
            label=""
            name="selectAll"
            checked={isCheckedAllProcedures()}
            handleChange={handleSelectAllProcedures}
          />
          <div className="search-input">
            <Icon.Search />
            <input
              type="search"
              placeholder="Search procedures / code"
              value={searchValue}
              onChange={(e: SyntheticEvent<HTMLInputElement>) =>
                setSearchValue(e.currentTarget.value)
              }
            />
          </div>
          <Box ml="auto" mr="-4px">
            <StyledDropdown
              ref={wrapperRef}
              onClick={handleToggle}
              className="procedure-filter-dropdown"
            >
              <DropdownButton>
                <Icon.Filter
                  className={filterOption !== '' ? 'filtered' : ''}
                  title="Filter "
                />
              </DropdownButton>
              {open === true && (
                <DropdownMenu>
                  <FilterMenu>
                    {procedureFilterOptions.map(option => (
                      <FilterMenuItem
                        key={option.id}
                        selected={option.value === filterOption ? true : false}
                        onClick={() => setFilterOption(option.value)}
                      >
                        {option.icon}
                        <FilterMenuItemLabel>
                          {option.value}
                        </FilterMenuItemLabel>
                        {option.value === filterOption ? <Icon.Check /> : null}
                      </FilterMenuItem>
                    ))}
                  </FilterMenu>
                  <FilterMenuActions>
                    <button
                      onClick={(e: SyntheticEvent<HTMLButtonElement>) => {
                        e.stopPropagation();
                        setFilterOption('');
                        setOpen(false);
                        setAppliedFilterOption('');
                      }}
                    >
                      Clear filter
                    </button>
                    <button
                      onClick={(e: SyntheticEvent<HTMLButtonElement>) => {
                        e.stopPropagation();
                        setAppliedFilterOption(filterOption);
                        setOpen(false);
                      }}
                      style={{ fontWeight: 500, color: 'var(--primary-color)' }}
                    >
                      Apply
                    </button>
                  </FilterMenuActions>
                </DropdownMenu>
              )}
            </StyledDropdown>
          </Box>
        </ProcedureSearch>
        <CardBody flex="1 0 auto" overflowY="auto" height="1px">
          <ProcedureList>
            {procedures &&
              procedures.length > 0 &&
              procedures?.map(procedureItem => {
                const isPublished = checkIsPublished(procedureItem);
                const isSelected = isProcedureChecked(
                  procedureItem.ProcedureId,
                );
                return (
                  <ProcedureItem
                    brokenLinks={brokenLinks}
                    setRenameFailed={setRenameFailed}
                    renameFailed={renameFailed}
                    key={procedureItem.ProcedureId}
                    procedureItem={procedureItem}
                    renameProc={renameProc}
                    isPublished={isPublished}
                    isSelected={isSelected}
                    selectProcedure={selectProcedure}
                    changeVisibility={(cb: (isSucess: boolean) => void) => {
                      hideDisplayProcedure(procedureItem, cb);
                    }}
                    setRenameProc={(procedureItem?: ProcedureListType) => {
                      setRenameProc(procedureItem);
                    }}
                    updateName={handleRenameProcedures}
                    toggleLinkedGlobalAccounts={() =>
                      toggleLinkedGlobalAccounts(procedureItem)
                    }
                    toggleLinkedAccounts={() =>
                      toggleLinkedAccounts(procedureItem)
                    }
                    selectedProcedures={selectedProcedures}
                    isCheckedAllProcedures={isCheckedAllProcedures}
                    autoSaveContent={autoSaveContent}
                    procedureDetails={procedureDetails}
                    setUserTyped={setUserTyped}
                    setEditorChanges={setEditorChanges}
                    procedures={procedures}
                  />
                );
              })}
          </ProcedureList>
          {!isProcedureLoading && procedures && procedures.length <= 0 && (
            <NoDataImage height="100%" feedback="No data available" />
          )}
        </CardBody>
        <CardFooter backgroundColor="transparent">
          <FileBrowserBtn onClick={handleFileBrowser}>
            <Icon.Folder />
            <span className="label">File Browser</span>
            <Icon.ChevronRight
              title=""
              ariaHidden="true"
              fill="var(--color-neutral-contrast-05)"
            />
          </FileBrowserBtn>
        </CardFooter>
      </Card>

      <LinkedGlobalAccountsList
        showLinkedAccounts={!!globalProcedure}
        toggleLinkedAccounts={toggleLinkedGlobalAccounts}
        globalProcedure={globalProcedure}
      />
      <LinkedAccountsList
        showLinkedAccounts={!!linkedProcedure}
        toggleLinkedAccounts={toggleLinkedAccounts}
        linkedProcedure={linkedProcedure}
      />
    </>
  );
}

function ProcedureItem({
  procedureItem,
  renameProc,
  isPublished,
  isSelected,
  selectProcedure,
  changeVisibility,
  setRenameProc,
  updateName,
  toggleLinkedGlobalAccounts,
  toggleLinkedAccounts,
  selectedProcedures,
  isCheckedAllProcedures,
  setRenameFailed,
  renameFailed,
  brokenLinks,
  autoSaveContent,
  procedureDetails,
  setUserTyped,
  setEditorChanges,
  procedures,
}: {
  procedures: any;
  brokenLinks: any;
  procedureItem: ProcedureListType;
  renameProc?: ProcedureListType;
  isPublished: boolean;
  isSelected?: boolean;
  selectProcedure: (
    isAppend: boolean,
    procedureItem: ProcedureListType,
    doubleClicked?: boolean,
  ) => void;
  changeVisibility: (cb: (isSuccess: boolean) => void) => void;
  setRenameProc: (procedureItem?: ProcedureListType) => void;
  updateName: (newName: string, cb: (isSuccess: boolean) => void) => void;
  toggleLinkedGlobalAccounts: () => void;
  toggleLinkedAccounts: () => void;
  isCheckedAllProcedures: () => boolean;
  selectedProcedures: Array<number> | undefined;
  setRenameFailed: any;
  renameFailed: boolean;
  autoSaveContent: (cb: any) => void;
  procedureDetails: ProcedureInfoType | undefined;
  setUserTyped: any;
  setEditorChanges: (value: boolean) => void;
}) {
  const [newName, setNewName] = useState<string>();
  const [visibility, setVisibility] = useState<boolean>();
  const editMode = useMemo(() => {
    return renameProc?.ProcedureId === procedureItem.ProcedureId;
  }, [renameProc, procedureItem]);
  const procedureNameRef = useRef<string>();
  const procedureVisibilityRef = useRef<boolean>();

  const [isNameEdited, setIsNameEdited] = useState(false);
  const [editedProcedure, setEditedProcedure] = useState<ProcedureListType>();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const isFirstTimeProcedure =
    !procedureItem?.Procedure?.Versions.length && !procedureItem.IsVisible;
  const procedureNameInputRef = useRef<HTMLInputElement>(
    document.createElement('input'),
  );

  useEffect(() => {
    if (editMode) procedureNameInputRef?.current.focus();
  }, [editMode, procedureNameInputRef]);

  useEffect(() => {
    if (
      !procedureNameRef.current ||
      procedureNameRef.current !== procedureItem?.Procedure?.Name
    ) {
      setNewName(procedureItem.Procedure.Name);
      procedureNameRef.current = procedureItem?.Procedure?.Name;
    }
  }, [procedureItem]);

  useEffect(() => {
    if (
      !procedureVisibilityRef.current ||
      procedureVisibilityRef.current !== procedureItem?.IsVisible
    ) {
      setVisibility(procedureItem.IsVisible);
      procedureVisibilityRef.current = procedureItem?.IsVisible;
    }
  }, [procedureItem]);

  useEffect(() => {
    if (isNameEdited) {
      setNewName(editedProcedure?.Procedure?.Name);
    }
  }, [selectedProcedures, isNameEdited, editedProcedure]);

  useEffect(() => {
    if (renameFailed) {
      setNewName(procedureItem?.Procedure?.Name);
    }
  }, [procedureItem?.Procedure?.Name, renameFailed]);

  useEffect(() => {
    if (!editMode) {
      setTooltipOpen(false);
    }
  }, [editMode]);

  const count = useMemo(() => {
    if (brokenLinks && brokenLinks.length > 0 && procedureItem?.ProcedureId) {
      let totalCounts = 0;
      brokenLinks.forEach((item: any) => {
        if (item.Id === procedureItem?.ProcedureId) {
          item.ProcedureLinks.forEach((link: any) => {
            if (link?.Link?.IgnoreIsBroken === false) {
              totalCounts = totalCounts + link.Count;
            }
          });
        }
      });
      return totalCounts;
    }
  }, [brokenLinks, procedureItem?.ProcedureId]);
  return (
    <ProcedureListItem
      unpublished={isPublished || procedureItem.IsPublished}
      editMode={editMode}
      isSelected={isSelected}
      draft={isFirstTimeProcedure}
    >
      <Checkbox
        label=""
        name={`${procedureItem?.Procedure?.Name}${procedureItem.ProcedureId}`}
        checked={isSelected}
        handleChange={() => {
          setRenameProc(undefined);
          selectProcedure(true, procedureItem);
        }}
      />
      {/* if hidden procedure give eye off icon */}
      {newName === '0000_POLICIESMENU.html' ? (
        <Tooltip
          arrow={true}
          position="top"
          title="The Policy Menu cannot display a copy of itself."
          style={{ width: '20px', height: '20px' }}
        >
          <button
            style={{
              cursor: 'default',
            }}
            className="procedure__visibility"
            disabled
          >
            <Icon.EyeOff fill="var(--color-neutral-contrast-05)" />
          </button>
        </Tooltip>
      ) : (
        <button
          className="procedure__visibility"
          onClick={() => {
            let newContent = '';
            if ((window as any)?.tinymce?.activeEditor?.initialized === true) {
              newContent = `<!DOCTYPE html><html><head><title></title><base href='/api/procedures/V1/accountfolders/${
                procedureDetails?.AccountProcedure?.AccountId
              }/Policies/'></head><body>${(
                window as any
              )?.tinymce?.activeEditor?.getContent()}</body></html>`;
            }
            setRenameProc(undefined);
            setEditorChanges(false);
            if (newContent !== procedureDetails?.Content)
              autoSaveContent(() => {
                console.log('inside');
                setUserTyped(false); ////check later
                procedures.forEach((item: any) => {
                  if (
                    item.ProcedureId ===
                    procedureDetails?.AccountProcedure?.Procedure?.Id
                  ) {
                    if (
                      (!item.IsLinked || item.IsParentLinked) &&
                      !item.Procedure?.IsGlobal
                    ) {
                      item.IsPublished = true;
                    }
                  }
                  return item;
                });
              });
            const condition =
              selectedProcedures &&
              selectedProcedures.find(
                item => item !== procedureItem.ProcedureId,
              );
            if (condition) {
              selectProcedure(false, procedureItem);
            }
            changeVisibility(isSuccess => {
              if (!isSuccess) {
                setVisibility(procedureVisibilityRef.current);
              }
            });
            setVisibility(!visibility);
          }}
        >
          {visibility === true ? (
            <Icon.Eye fill="var(--color-body-text)" />
          ) : (
            <Icon.EyeOff fill="var(--color-neutral-contrast-05)" />
          )}
        </button>
      )}

      {/* if hidden procedure give this style to input style={{ color: 'var(--color-neutral-contrast-05)' }}  */}
      <Tooltip
        arrow={true}
        open={tooltipOpen}
        position="top"
        html={
          <div style={{ maxWidth: '200px' }}>
            Special characters not allowed in file name (+, #, &#x7b;, &#x7d;,
            `, %, &amp;, /, *, ^, $, &#39;, ;, @, =, |, ~, !, [, ], &quot;,
            &#44;)
          </div>
        }
        style={{ flex: 1 }}
        className={`${
          isFirstTimeProcedure ? 'procedure__tooltip__wrapper' : ''
        }`}
      >
        <input
          type="text"
          className="procedure__name"
          ref={procedureNameInputRef}
          value={newName}
          title={procedureItem?.Procedure?.Name}
          style={{
            color:
              visibility === false ? 'var(--color-neutral-contrast-05)' : '',
          }}
          readOnly={!editMode}
          onClick={
            editMode
              ? undefined
              : () => {
                  //REMOVED CONDITION TO MAKE INITIAL CONDITON OF SELECTION USING TITLE WORK
                  // const condition =
                  //   selectedProcedures &&
                  //   selectedProcedures.find(
                  //     item => item !== procedureItem.ProcedureId,
                  //   );
                  // if (condition) {
                  setRenameProc(undefined);
                  selectProcedure(false, procedureItem);
                  // }
                }
          }
          onChange={(e: SyntheticEvent<HTMLInputElement>) => {
            setNewName(e.currentTarget.value);
            const format = /[*|"[\]{}';@&$+#=~,`%^!/]/;
            if (format.test(e.currentTarget.value)) {
              setTooltipOpen(true);
            } else {
              setTooltipOpen(false);
            }
          }}
        />
      </Tooltip>

      {renameProc !== undefined && (
        <Stack direction="x" gap={2} className="procedure__name-edit">
          <button
            disabled={tooltipOpen}
            title="Rename"
            onClick={() => {
              setRenameFailed(false);

              if (!newName) {
                showErrorToast({
                  message: 'Procedure should not be empty',
                });
                return;
              }
              let newContent = '';
              if (
                (window as any)?.tinymce?.activeEditor?.initialized === true
              ) {
                newContent = `<!DOCTYPE html><html><head><title></title><base href='/api/procedures/V1/accountfolders/${
                  procedureDetails?.AccountProcedure?.AccountId
                }/Policies/'></head><body>${(
                  window as any
                )?.tinymce?.activeEditor?.getContent()}</body></html>`;
              }
              if (newContent !== procedureDetails?.Content)
                autoSaveContent(() => {
                  setUserTyped(false);
                  setEditorChanges(false);
                  procedures.forEach((item: any) => {
                    if (
                      item.ProcedureId ===
                      procedureDetails?.AccountProcedure?.Procedure?.Id
                    ) {
                      if (
                        (!item.IsLinked || item.IsParentLinked) &&
                        !item.Procedure?.IsGlobal
                      ) {
                        item.IsPublished = true;
                      }
                    }
                    return item;
                  });
                });
              updateName(newName, isSuccess => {
                if (!isSuccess) {
                  setNewName(procedureNameRef.current);
                }
              });

              setRenameProc();
              setIsNameEdited(false);
              if (!newName.endsWith('.html')) {
                if (!procedureItem?.Procedure.Name.endsWith('.htm')) {
                  const name = newName.substring(newName.lastIndexOf('.') + 1);
                  const extension = `${name}.html`;
                  setNewName(newName.replace(name, extension));
                } else {
                  const name = newName.substring(newName.lastIndexOf('.') + 1);
                  const extension = `${name}.html`;
                  setNewName(newName.replace(name, extension));
                }
              }
            }}
          >
            <Icon.Check title="Rename" />
          </button>
          <button
            title="Cancel"
            onClick={() => {
              // cancel new name
              setTooltipOpen(false);
              setIsNameEdited(false);
              setRenameProc();
              setNewName(procedureItem?.Procedure.Name);
            }}
          >
            <Icon.X title="Cancel" />
          </button>
        </Stack>
      )}

      {isFirstTimeProcedure && (
        <Tooltip
          animation="fade"
          title="Publish to make available to Proteus Call Center"
          position="top"
          arrow={true}
          className="procedure__flag__tooltip"
        >
          <span className="procedure__flag">Draft</span>
        </Tooltip>
      )}

      <Stack direction="x" gap={2} className="procedure__attributes">
        {/* procedure is a linked procedure (like 7 icon)*/}
        <Icon.LinkedProcedure
          fill={
            procedureItem?.IsLinked === true
              ? 'var(--color-state-info)'
              : procedureItem?.IsParentLinked === true
              ? 'var(--color-state-success)'
              : 'var(--color-neutral-contrast-03)'
          }
        />
        {/* // global procedure(var(--color-state-success)) or local procedure(var(--color-neutral-contrast-03)) change the fill color*/}
        <Icon.Globe
          fill={
            procedureItem?.Procedure?.IsGlobal === true
              ? 'var(--color-state-success)'
              : 'var(--color-neutral-contrast-03)'
          }
        />
        <ProcedureBrokenLinks count={count} />
      </Stack>

      <Stack direction="x" gap={2} className="procedure__actions">
        {/* procedure is a global procedure and show the linked accounts to that procedure in a panel */}
        <button
          className={
            procedureItem?.IsLinked === true ||
            procedureItem?.IsParentLinked === true
              ? ''
              : 'hidden'
          }
          onClick={toggleLinkedAccounts}
        >
          <Icon.LinkedProcedure
            title={
              procedureItem?.IsLinked ? 'Linked (child)' : 'Linked (parent)'
            }
          />
        </button>
        <button
          className={
            procedureItem?.Procedure?.IsGlobal === true ? '' : 'hidden'
          }
          onClick={toggleLinkedGlobalAccounts}
        >
          <Icon.Globe title="View linked accounts" />
        </button>

        <button
          onClick={() => {
            if (!isCheckedAllProcedures()) {
              selectProcedure(false, procedureItem, true);
            }
            setRenameProc(procedureItem);
            setIsNameEdited(true);
            setEditedProcedure(procedureItem);
          }}
        >
          <Icon.Edit title="Rename procedure" />
        </button>
      </Stack>
    </ProcedureListItem>
  );
}
